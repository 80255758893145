import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { CLIENT_FILTERS } from "../../../constants/PreferenceKeys";
import {
  CLIENT_MANAGEMENT_CREATE_PAGE,
  CLIENT_MANAGEMENT_EDIT_PAGE,
  CLIENT_MANAGEMENT_VIEW_PAGE,
} from "../../../constants/routes";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../../maintenance/ModalViewer";
import { ClientManagementContext } from "../ClientManagementProvider";
import ClientListFilter from "./ClientListFilter";
import ExportForm from "./ExportForm";

const ClientListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const history = useHistory();

  const clientManagementContext = useContext(ClientManagementContext);

  const { clientsList, pagination } = clientManagementContext;
  const { filterParams, handleApplyFilter } = clientManagementContext;
  const { archiveClient, unarchiveClient, deleteClient, clientLoading, isFilterApplied, showFilter, sfState } =
    clientManagementContext;
  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    const filters = localStorage.getItem(CLIENT_FILTERS);
    if (filters) handleApplyFilter(JSON.parse(filters));
  }, []);

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    if (e) {
      setSearchText(e.target.value);
    } else {
      setSearchText("");
    }
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    history.push(CLIENT_MANAGEMENT_EDIT_PAGE.replace(":clientId", id));
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (clientId) => {
    archiveClient(clientId)
      .then((res) => {
        successToast({ description: "Client Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive client" });
      });
  };
  const handleUnArchive = async (clientId) => {
    unarchiveClient(clientId)
      .then((res) => {
        successToast({ description: "Client Unarchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive client" });
      });
  };

  const handleDeleteClient = async (clientId) => {
    deleteClient(clientId)
      .then((res) => {
        successToast({ description: "Client deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting client" });
      });
  };
  const handleConfirmationDeleteClient = async (clientId) => {
    confirmationSweetAlert({
      id: clientId,
      handleConfirmation: handleDeleteClient,
    });
  };
  const handleCreateUser = () => {
    history.push(CLIENT_MANAGEMENT_CREATE_PAGE);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  const currentItems = clientsList?.items || [];
  let exportItems = [];
  const arrayList = currentItems;

  arrayList.map((item) => {
    delete item.createdById;
    const user = item?.clientUsers && `${item.clientUsers.firstName} ${item.clientUsers.lastName}`;
    if (item.clientUsers) {
      item["ClientUser"] = user;
      delete item.clientUsers;
    }
    exportItems = [...exportItems, item];
  });

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const onSuccessfulModal = () => {
    toggleExportModal();
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Client List
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {clientsList?.pagination?.totalCount} clients.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedClientStatus} title={"Status"} isMap={false} isStr={false} />
              <FilteredByList data={filterParams.city} title={"City"} isMap={false} isStr={true} isNotMap={true} />
              <FilteredByList data={filterParams.email} title={"Email"} isMap={false} isStr={true} isNotMap={true} />
              <FilteredByList
                data={filterParams.phoneNumber}
                title={"Phone Number"}
                isMap={false}
                isStr={true}
                isNotMap={true}
              />
              <FilteredByList
                data={filterParams.mobileNumber}
                title={"Mobile Number"}
                isMap={false}
                isStr={true}
                isNotMap={true}
              />

              <FilteredByList data={filterParams.searchText} title={"Searched by"} isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getClients", "createClient", "updateClient", "deleteClient"]}>
                    <li>
                      <Button
                        disabled={clientsList?.pagination?.totalCount === 0}
                        id="client-export-btn"
                        className={`btn btn-white btn-outline-light`}
                        type="export"
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <li className="nk-block-tools-opt">
                    <Check permission={["createClient"]}>
                      {" "}
                      <Button id="client-plus-btn" color="primary" className="btn-icon" onClick={handleCreateUser}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </Check>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable id="client-data-table" className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="client-search"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div id="client-filter-btn" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                    {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                    <Icon name="filter-alt"></Icon>
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    id="client-back-arrow"
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="client-search-input"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by user, email and status.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button id={"client-search-submit-btn"} className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {clientLoading ? <></> : <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    Client Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="sm">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "city"
                        ? handleNameSort("DESC", "city")
                        : handleNameSort("ASC", "city");
                    }}
                  >
                    City
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "city");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "city"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "city");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "city"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "mobileNumber"
                        ? handleNameSort("DESC", "mobileNumber")
                        : handleNameSort("ASC", "mobileNumber");
                    }}
                  >
                    Mobile
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "mobileNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "mobileNumber"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "mobileNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "mobileNumber"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phoneNumber"
                        ? handleNameSort("DESC", "phoneNumber")
                        : handleNameSort("ASC", "phoneNumber");
                    }}
                  >
                    Phone Number
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "phoneNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phoneNumber"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "phoneNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "phoneNumber"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "email"
                        ? handleNameSort("DESC", "email")
                        : handleNameSort("ASC", "email");
                    }}
                  >
                    Email
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "email");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "email"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "email");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "email"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>

            <Sidebar toggleState={showFilter}>
              <ClientListFilter />
            </Sidebar>

            {/*Head*/}
            {clientLoading ? (
              <></>
            ) : (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <Link to={`${CLIENT_MANAGEMENT_VIEW_PAGE}`.replace(":clientId", item.id)}>
                        <div className="user-card">
                          <UserAvatar theme={"primary"} className="xs" text={findUpper(item.name)} />
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.name}
                              style={{
                                width: item.name?.length > 50 ? "350px" : "",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item.name || "-"}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span className="noWrap" >{item.city || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap" >{item.mobileNumber || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap" >{item.phoneNumber || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span className="noWrap" >{item.email || "-"}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              id="client-menu-btn"
                              tag="a"
                              className="dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <Check permission={["updateClient"]}>
                                    <li /* className="nk-tb-action-hidden" */ onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        id="client-edit-button"
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit Client</span>
                                      </DropdownItem>
                                    </li>
                                  </Check>
                                  {item.isArchived ? (
                                    <React.Fragment>
                                      <li className="divider"></li>
                                      <li onClick={() => handleUnArchive(item.id)}>
                                        <DropdownItem
                                          id="client-unarchive-btn"
                                          tag="a"
                                          href="#unarchive"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="unarchive"></Icon>
                                          <span>UnArchive Client</span>
                                        </DropdownItem>
                                      </li>
                                      <li className="divider"></li>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <li onClick={() => handleArchive(item.id)}>
                                        <DropdownItem
                                          id="client-archive-btn"
                                          tag="a"
                                          href="#archive"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="archive"></Icon>
                                          <span>Archive Client</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  )}
                                  <Check permission={["deleteClient"]}>
                                    {item.status !== "SUSPENDED" && (
                                      <>
                                        <li className="divider"></li>
                                        <li onClick={() => handleConfirmationDeleteClient(item.id)}>
                                          <DropdownItem
                                            id="client-delete-btn"
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete Client</span>
                                          </DropdownItem>
                                        </li>

                                        <li className="divider"></li>
                                      </>
                                    )}
                                  </Check>
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>}
          {clientLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {clientsList.pagination && clientsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={clientsList.pagination.totalCount}
                paginate={clientManagementContext.updateShowListPage}
                currentPage={clientsList.pagination.currentPage}
                paginatePage={clientManagementContext.updateShowListSize}
              />
            ) : (
              !clientLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        <ModalViewer
          title="Client - Select the Export Fields"
          isOpen={isExpotModalOpen}
          size="md"
          toggleModal={toggleExportModal}
          component={getExportComponent()}
        />
      </Block>
    </React.Fragment>
  );
};
export default ClientListCompact;
